footer {
  text-align: center;
  padding: 60px 0 20px 0;
  width: 100%;
}

span {
  flex: 1;
}

footer a {
  padding: 10px;
  text-decoration: underline;
  font-size: 17px;
}

footer p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2;
  opacity: 0.84;
  transition: opacity 0.2s ease-in-out;
  padding: 30px;
}

footer p:hover {
  opacity: 1;
}

@media (max-width: 900px) {
  footer {
    padding-top: 0;
  }
  footer nav {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    height: 100%;
  }
  footer nav a {
    font-size: 1.2rem;
  }
}
