.contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 80rem;
}

.contact h2 {
  color: var(--white);
  font-size: 3rem;
  margin-top: 4rem;
}

.contact h3{
    color: var(--white);
    font-weight: 300;
    opacity: .84;
}

.contact h3 span{
  font-weight: 600;
  font-style: italic;
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  color: var(--white);
  width: 100%;
}

.contact__form-label{
  color: #666;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  display: block;
}

.contact__form-input {
  color: #333;
  padding: 1rem;
  width: 100%;
  font-size: 1.2rem;
  letter-spacing: 0;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 1px 1px 5px var(--white);
}

#message {
  resize: none;
}

.btn{
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  padding: 15px 40px;
  background-color: var(--white);
  color: var(--black);
  border: none;
}

.btn:hover{
  background-color: rgb(80, 80, 80);
  color: #fff;
}

.contact p{
  color: red;
  font-size: 18px;
  padding-bottom: 20px;
}